import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { Override, Section, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<Helmet>
			<title>
				kursin.com - modern web development
			</title>
			<meta name={"description"} content={"Launch your startup in a couple of weeks. We are creating great web or mobile applications quickly using modern NoCode tools or pure code."} />
			<meta property={"og:title"} content={"kursin.com - modern web development"} />
			<meta property={"og:description"} content={"Launch your startup in a couple of weeks. We are creating great web or mobile applications quickly using modern NoCode tools or pure code."} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1589561253898-768105ca91a8?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/k.png?v=2020-12-03T10:13:59.575Z"} type={"image/x-icon"} />
			<meta name={"msapplication-TileColor"} content={"#5b6ecf"} />
			<script async src={`https://www.googletagmanager.com/gtag/js?id=UA-176560010-1`}></script>
     		<script>
			{`
      	    window.dataLayer = window.dataLayer || [];
          	function gtag(){dataLayer.push(arguments);}
          	gtag('js', new Date());
          	gtag('config', "UA-176560010-1");
        	`}
		   	</script>
		</Helmet>
		<Components.Header>
			<Override slot="link" />
		</Components.Header>
		<Section
			padding="25px 0 75px 0"
			sm-align-items="center"
			sm-justify-content="center"
			quarkly-title="Hero"
			lg-padding="25px 0 25px 0"
			sm-padding="0px 0 25px 0"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				background="linear-gradient(0deg,rgba(255, 255, 255, 0.7) 100%,rgba(0,0,0,1) 100%),#FFFFFF url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f69eff9f47dbfd5dd24cbef_background-1201003_1920.jpg?v=2020-12-02T13:16:17.568Z)"
				height="620px"
				align-items="center"
				justify-content="center"
				sm-width="100%"
				lg-height="520px"
				md-padding="0px 24px 0px 24px"
				md-margin="0px 16px 0px 16px"
				margin="0px 32px 0px 32px"
				min-width="auto"
				md-width="100%"
			/>
			<Text
				color="--dark"
				font="normal 500 72px/1.2 --fontFamily-googleMontserrat"
				margin="16px 0px 0px 0px"
				sm-text-align="center"
				sm-width="80%"
				lg-text-align="center"
				lg-font="normal 500 42px/1.2 --fontFamily-googleMontserrat"
				text-align="center"
				as="h1"
				width="805px"
				md-font="normal 500 30px/1.2 --fontFamily-googleMontserrat"
				md-width="400px"
				lg-width="450px"
			>
				Launch your startup in a couple of weeks
			</Text>
			<Text
				font="normal 300 20px/1.5 --fontFamily-sans"
				color="--dark"
				margin="40px 0px 40px 0px"
				sm-text-align="center"
				sm-width="100%"
				opacity="0.7"
				md-text-align="center"
				text-align="center"
				xl-width="500px"
				width="500px"
				lg-width="500px"
				md-width="450px"
				as="h2"
			>
				We are creating great web or mobile applications quickly using modern NoCode tools or pure code
			</Text>
			<Link
				href="/about"
				text-decoration-line="initial"
				color="--darkL2"
				background="--color-light"
				padding="12px 24px 12px 24px"
				font="--base"
				letter-spacing="0.5px"
				hover-transform="translateY(-4px)"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				border-width="1px"
				border-style="solid"
			>
				LEARN MORE
			</Link>
		</Section>
		<Section
			padding="90px 0px 100px 0px"
			sm-padding="24px 0 24px 0"
			quarkly-title="About"
			background="--color-lightD1"
			border-color="--color-lightD2"
			border-style="solid"
			border-width="1px 0px 1px 0px"
			box-sizing="border-box"
			lg-padding="50px 30px 50px 30px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Text
				text-align="center"
				font="--base"
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				color="--dark"
				lg-margin="0px 0px 6px 0px"
				lg-text-align="center"
				quarkly-title="Title"
				text-transform="uppercase"
			>
				What we believe in
			</Text>
			<Text
				font="normal 300 28px/1.2 --fontFamily-googleMontserrat"
				margin="0px 0px 0px 0px"
				letter-spacing="1px"
				color="--dark"
				text-align="center"
				width="85%"
				lg-font="--lead"
				sm-font="--base"
				xl-width="800px"
				md-width="500px"
				sm-width="350px"
			>
				Your business doesn't need to spend a lot of money just to validate you idea.
			</Text>
		</Section>
		<Section
			padding="70px 0 70px 0"
			quarkly-title="Info"
			lg-padding="50px 0px 50px 0px"
			md-padding="25px 0px 25px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Stack>
				{"    "}
				<StackItem
					width="40%"
					display="flex"
					lg-width="100%"
					lg-order="2"
					lg-margin="0px 0px 0px 0px"
				>
					<Override
						slot="StackItemContent"
						flex-direction="column"
						justify-content="flex-start"
						lg-align-items="center"
						align-items="center"
						padding="0px 30px 0px 0px"
						lg-padding="0px 0px 0px 0px"
					/>
					{"        "}
					<Text
						font="--base"
						margin="0px 0px 10px 0px"
						letter-spacing="1px"
						color="--dark"
						opacity="0.6"
						lg-text-align="center"
						lg-margin="0px 0px 6px 0px"
						quarkly-title="Title"
						text-transform="uppercase"
					>
						Team
					</Text>
					<Text
						font="normal 300 38px/1.2 --fontFamily-googleMontserrat"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						lg-margin="0px 0px 18px 0px"
						sm-font="--headline3"
						md-font="--headline3"
					>
						Who We Are
					</Text>
					<Text
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="left"
						lg-margin="0px 0px 18px 0px"
						sm-font="--base"
						text-align="center"
					>
						Team of specialists in modern NoCode tools like Bubble and experienced full stack developers.
					</Text>
					<Link
						href="/team"
						text-decoration-line="initial"
						color="--dark"
						padding="8px 18px 8px 18px"
						font="--base"
						letter-spacing="0.5px"
						text-align="center"
						margin="9px 0px 0px 0px"
						background="--color-light"
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						border-width="1px"
						border-style="solid"
					>
						LEARN MORE
					</Link>
					{"    "}
				</StackItem>
				{"    "}
				<StackItem
					width="60%"
					display="flex"
					padding="0px 0px 0px 0px"
					miniPk-width="59%"
					lg-width="100%"
					lg-order="1"
					lg-margin="0px 0px 10px 0px"
					quarkly-title="Illustration"
				>
					{"        "}
					<Box
						width="100%"
						background="--color-light url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f732d542bf588d9e4ee567e_conference-2533871_1280.jpg?v=2020-12-02T13:25:56.513Z) center center/100% no-repeat"
						margin="0px 0px 0px 0px"
						padding="0px 0px 60% 0px"
						height="0px"
						hover-background="--color-lightD2 url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f732d542bf588d9e4ee567e_conference-2533871_1280.jpg?v=2020-12-02T13:25:56.513Z) center center/110% no-repeat"
						transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					/>
					{"    "}
				</StackItem>
			</Stack>
			<Stack margin="80px -16px -16px -16px" lg-margin="50px -16px -16px -16px" md-margin="30px -16px -16px -16px">
				{"        "}
				<StackItem
					width="60%"
					display="flex"
					padding="0px 0px 0px 0px"
					miniPk-width="59%"
					lg-width="100%"
					lg-margin="0px 0px 10px 0px"
					quarkly-title="Illustration"
				>
					<Override slot="StackItemContent" lg-width="100%" />
					{"        "}
					<Box
						width="100%"
						background="--color-lightD2 url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f732d534bdd892badbd3b0c_business-4051773_1280.jpg?v=2020-12-02T13:26:25.170Z) center center/100% no-repeat"
						margin="0px 0px 0px 0px"
						padding="0px 0px 60% 0px"
						height="0px"
						hover-background="--color-lightD2 url(https://uploads.quarkly.io/5fa7d45bbadc03001fb0bf8d/images/5f732d534bdd892badbd3b0c_business-4051773_1280.jpg?v=2020-12-02T13:26:25.170Z) center center/110% no-repeat"
						transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					/>
					{"    "}
				</StackItem>
				<StackItem width="40%" display="flex" lg-margin="0px 0px 0px 0px" lg-width="100%">
					<Override
						slot="StackItemContent"
						flex-direction="column"
						justify-content="flex-start"
						lg-align-items="center"
						align-items="center"
						padding="0px 0px 0px 30px"
						lg-padding="0px 0px 0px 0px"
					/>
					{"        "}
					<Text
						font="--base"
						margin="0px 0px 10px 0px"
						letter-spacing="1px"
						color="--dark"
						opacity="0.6"
						lg-margin="0px 0px 6px 0px"
						lg-text-align="center"
						quarkly-title="Title"
						text-transform="uppercase"
					>
						OUR DEVELOPING PROCESS
					</Text>
					<Text
						font="normal 300 38px/1.2 --fontFamily-googleMontserrat"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						lg-margin="0px 0px 18px 0px"
						sm-font="--headline3"
						md-font="--headline3"
					>
						What we do
					</Text>
					<Text
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
						sm-text-align="left"
						lg-text-align="left"
						lg-margin="0px 0px 18px 0px"
						sm-font="--base"
						text-align="center"
					>
						Our development experience has reached such heights that we can confidently announce the development of quality projects in a short time.
					</Text>
					<Link
						href="/process"
						text-decoration-line="initial"
						color="--dark"
						padding="8px 18px 8px 18px"
						font="--base"
						letter-spacing="0.5px"
						text-align="center"
						margin="9px 0px 0px 0px"
						background="--color-light"
						hover-transform="translateY(-4px)"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						border-width="1px"
						border-style="solid"
						border-color="#04080c"
					>
						LEARN MORE
					</Link>
					{"    "}
				</StackItem>
			</Stack>
		</Section>
		<Components.Upfooter />
		<Components.FooterMain>
			<Override
				slot="stackItem"
				width="100%"
				md-width="100%"
				lg-width="100%"
				xl-width="100%"
				sm-width="100%"
			/>
		</Components.FooterMain>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
	</Theme>;
});